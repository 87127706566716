import {TextField as MuiTextField} from '@material-ui/core';
import Autocomplete, {AutocompleteRenderInputParams} from '@material-ui/lab/Autocomplete';
import React, {ReactElement} from 'react';
import {Field} from 'react-final-form';

type Props = {
    name : string;
    label : string;
    required : boolean;
    options : string[];
};

const MuiRffAutocomplete = ({name, label, required, options} : Props) : ReactElement => {
    return (
        <Field name={name}>
            {(props) => {
                const error = props.meta.error || props.meta.submitError;
                const hasError = error && (props.meta.dirty || props.meta.submitFailed);
                                                
                return <Autocomplete
                    id={name}
                    freeSolo
                    value={props.input.value}
                    options={options}
                    onChange={(event : React.ChangeEvent<{}>, optionValue : string | null) : void => {
                        return props.input.onChange(optionValue === null ? '' : optionValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        return props.input.onChange(newInputValue === null ? '' : newInputValue);
                    }}
                    renderInput={(params : AutocompleteRenderInputParams) => (
                        <MuiTextField
                            {...params}
                            variant="outlined"
                            label={label}
                            placeholder={label}
                            helperText={hasError ? label + ' is required to publish' : ''}
                            error={hasError}
                            required={required}
                            fullWidth
                        />
                    )}
                />
            }}
        </Field>
    );
}

export default MuiRffAutocomplete;