import {IconButton, MuiThemeProvider , createMuiTheme} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import React, {ReactElement} from 'react';
import {useAuth0} from './components/Providers/Auth0Provider';
import Routes from './Routes';
import logo from './static/phcp_logo.png';
import {baseUrl} from './utils/Helpers';

const theme = createMuiTheme({
    overrides: {
        MuiContainer: {
            root: {
                width: '100%',
            },
        },
        MuiButtonBase: {
            root: {
                border: 'none',
                marginLeft: '10px',
                marginTop: '20px',
            },
        },
        MuiDialogActions: {
            root: {
                marginTop: '15px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
    content: {
        padding: theme.spacing(4),
        width: '100%',
    },
    signOutButton: {
        color: theme.palette.primary.contrastText,
    },
    logo: {
        height: '40px'
    },
}));

const Main = () : ReactElement => {
    const classes = useStyles();
    const auth0 = useAuth0();

    return (
        <>
            <AppBar position="static" style={{backgroundColor: 'rgb(0, 89, 138)'}}>
                <Toolbar>                    
                    <img src={logo} className={classes.logo} alt="PHCP Patrick Henry Creative Promotions"/>
                    <div className={classes.title}/>
                    <Tooltip title="Sign out">
                        <IconButton onClick={() => auth0.logout({
                            returnTo: baseUrl,
                        })} className={classes.signOutButton}>
                            <SignOutIcon/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <MuiThemeProvider theme={theme}>
                <Container maxWidth={false} className={classes.content}>
                    <Routes/>
                </Container>
            </MuiThemeProvider>
        </>
    );
};

export default Main;
