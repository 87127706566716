import {Item} from '../pages/Home';

export const optionsFilter = (
    items : Item[],
    prop : 'category' | 'duration' | 'classification' | 'type' | 'brand' | 'supplier' | 'availability',
    options : string[],
    prop2 ?: 'company',
) : Item[] => {
    const displayedItems : Item[] = [];

    if (!options.length) {
        return items;
    }

    if (items !== null) {
        items.forEach(item => {
            if (item === null || item === undefined) {
                return;
            }

            let foundTokens = 0;

            options.forEach(option => {
                if (prop !== 'supplier' && item[prop].toLowerCase().includes(option.toLowerCase())) {
                    foundTokens++;
                } else if (
                    prop === 'supplier'
                    && prop2 !== undefined
                    && item[prop][prop2].toLowerCase().includes(option.toLowerCase())
                ) {
                    foundTokens++;
                }
            });

            if (foundTokens) {
                displayedItems.push(item);
            }
        });
    }

    return displayedItems;
};

export const tokenFilter = (items : Item[],
    prop : 'name' | 'bottleSize' | 'summary' | 'supplier' | 'brand',
    searchValue : string,
    prop2 ?: 'emailAddress' | 'company',
) : Item[] => {
    const displayedItems : Item[] = [];

    if (searchValue === '') {
        return items;
    }

    if (items !== null) {
        items.forEach(item => {
            if (item === null || item === undefined) {
                return;
            }

            const tokens = searchValue.trim().split(' ');
            let foundTokens = 0;

            tokens.forEach(token => {
                if (prop !== 'supplier' && item[prop].toLowerCase().includes(token.toLowerCase())) {
                    foundTokens++;
                } else if (
                    prop === 'supplier'
                    && prop2 !== undefined
                    && item[prop][prop2].toLowerCase().includes(token.toLowerCase())
                ) {
                    foundTokens++;
                }
            });

            if (foundTokens === tokens.length) {
                displayedItems.push(item);
            }
        });
    }

    return displayedItems;
};