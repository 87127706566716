import {
    Typography,
    Grid,
    CircularProgress,
    FormControlLabel,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AnyObject} from 'final-form';
import parse from 'html-react-parser';
import {makeRequired, makeValidate, TextField} from 'mui-rff';
import {useSnackbar} from 'notistack';
import React, {useState, ReactElement, useCallback, useMemo} from 'react';
import {Form, Field} from 'react-final-form';
import * as yup from 'yup';
import {Item, FormOption} from '../pages/Home';
import tile from '../static/PHCP-diagonal-stripes.png';
import AlertDialog from './AlertDialog';
import DocumentUploader from './DocumentUploader';
import FileUploader from './ImageUploader';
import MuiRffAutocomplete from './MuiRffAutocomplete';
import {apiEndpoint, useApiFetch, useAuth0} from './Providers/Auth0Provider';

const theme = createMuiTheme({
    overrides: {
        MuiDialogContent: {
            root: {
                overflow: 'visible',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    gridItem: {
        paddingBottom: '8px',
    },
    uploadButton: {
        backgroundColor: 'rgb(0, 89, 138)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(0, 118, 171)'
        },
        border: 'none',
        marginTop: '20px',
    },
    cancelButton: {
        backgroundColor: 'rgb(188, 190, 192)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(209, 211, 212)'
        },
        marginTop: '20px',
    },
    saveButton: {
        backgroundColor: 'rgb(0, 174, 239)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(0, 204, 244)'
        },
        border: 'none',
        marginLeft: '10px',
        marginTop: '20px',
    },
    'saveButton:first-child': {
        marginLeft: 0
    },
    field: {
        marginTop: '10px',
        backgroundColor: 'yellow',
    },
    checkbox: {
        color: '#00AEEF',
    },
    dialogBody: {
        textAlign: 'center',
    },
    dialogContent: {
        overflow: 'scroll',
    },
    backdrop: {
        paddingBottom: '15px',
        fontWeight: 800,
        backgroundImage: `url(${tile})`,
        backgroundRepeat: 'repeat',
        backgroundColor: "#fff",
    },
    root: {
        '& > *': {
            // margin: theme.spacing(1),
            width: '25ch',
        },
    },
    modal: {
        overflow: 'scroll',
    },
    itemPreview: {
        maxHeight: '550px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    textField: {
        paddingBottom: '8px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'normal',
        color: 'rgb(0, 89, 138)',
        marginTop: '4px',
    },
    info: {
        marginTop: '4px',
    },
    imageContainer: {
        marginTop: '8px',
        border: '4px solid #fff',
        backgroundColor: 'rgb(199, 234, 251)',
        maxWidth: '32%',
        flexBasis: '32%',
        marginRight: '1.85%',
        padding: '15px 20px 20px 20px',
    },
    label: {
        fontSize: '13px',
        fontWeight: 'normal',
        color: 'rgb(102, 102, 102)',
        textTransform: 'uppercase',
        marginTop: '4px',
    },
    textarea: {
        width: '100%',
        float: 'left',
        backgroundColor: '#fff',
        border: 0,
        boxSizing: 'border-box',
        fontSize: '12pt',
        fontWeight: 400,
    },
    windowColumns: {
        width: '100%',
        marginTop: '8px',
    }
}));

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const schema = yup.object().shape({
    published: yup.boolean().label('Published'),
    name: yup.string().trim().label('Name').required("Name is required."),
    category: yup.string().trim().label('Category').when("published", {
        is: true,
        then: yup.string().trim().required("Category is required to publish.")
    }),
    availability: yup.string().trim().label('Availability').when("published", {
        is: true,
        then: yup.string().trim().required("Availability is required to publish.")
    }),
    duration: yup.string().trim().label('Duration').when("published", {
        is: true,
        then: yup.string().trim().required("Duration is required to publish.")
    }),
    classification: yup.string().trim().label('Classification').when("published", {
        is: true,
        then: yup.string().trim().required("Classification is required to publish.")
    }),
    type: yup.string().trim().label('Type').when("published", {
        is: true,
        then: yup.string().trim().required("Type is required to publish.")
    }),
    brand: yup.string().trim().label('Brand').when("published", {
        is: true,
        then: yup.string().trim().required("Brand is required to publish.")
    }),
    bottleSize: yup.string().trim().label('Bottle Size').when("published", {
        is: true,
        then: yup.string().trim().required("Bottle Size is required to publish.")
    }),
    summary: yup.string().trim().label('Summary').when("published", {
        is: true,
        then: yup.string().trim().required("Summary is required to publish.")
    }),
    description: yup.string().trim().label('Description').when("published", {
        is: true,
        then: yup.string().trim().required("Description is required to publish.")
    }),
    supplier_company: yup.string().trim().label('Supplier Company').when("published", {
        is: true,
        then: yup.string().trim().required("Supplier Company is required to publish.")
    }),
    supplier_contactName: yup.string().trim().label('Supplier Contact Name').when("published", {
        is: true,
        then: yup.string().trim().required("Supplier Contact Name is required to publish.")
    }),
    supplier_phoneNumber: yup.string().trim().label('Supplier Phone').when("published", {
        is: true,
        then: yup.string().trim().matches(phoneRegExp, 'Supplier Phone is not valid and is required to publish.')
    }),
    supplier_emailAddress: yup.string().email().label('Supplier Email Address').when("published", {
        is: true,
        then: yup.string().trim().required("Supplier Email Address is required to publish.")
    }),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);

type FormData = yup.InferType<typeof schema>;

type Props = {
    editMode : boolean;
    setEditMode : (editMode : boolean) => void;
    open : boolean;
    setSelectedItem : (item : Item | undefined) => void;
    item : Item;
    items : Item[];
    setItems : (items : Item[]) => void;
    distinctCategory : FormOption[];
    distinctDuration : FormOption[];
    distinctAvailability : FormOption[];
    distinctClassification : FormOption[];
    distinctType : FormOption[];
    distinctBrand : FormOption[];
    distinctSupplier : FormOption[];
};
const ItemDetailModal = ({
    open,
    setSelectedItem,
    item,
    items,
    setItems,
    editMode,
    setEditMode,
    distinctCategory,
    distinctDuration,
    distinctAvailability,
    distinctClassification,
    distinctType,
    distinctBrand,
    distinctSupplier,
} : Props) : ReactElement => {
    const auth0 = useAuth0();
    const admin = auth0.roles.includes('admin');
    const apiFetch = useApiFetch();
    const {enqueueSnackbar} = useSnackbar();

    const classes = useStyles();
    const [tempItem, setTempItem] = useState(item);
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
    const [tempImage, setTempImage] = React.useState<string | null>(item.image ? item.image.large : '');
    const [tempDocument, setTempDocument] = React.useState<string | null>();
    const [uploadTempImage, setUploadTempImage] = React.useState(false);
    const [uploadTempDocument, setUploadTempDocument] = React.useState(false);

    const patchItem = useCallback(async (item : Item) => {
        let url = new URL(`/items/${item.id}`, apiEndpoint);

        const saveItem = JSON.parse(JSON.stringify(item));

        if (uploadTempImage) {
            saveItem.image = tempImage;
        } else {
            delete saveItem.image;
        }

        if (uploadTempDocument) {
            saveItem.document = tempDocument;
        } else {
            delete saveItem.document;
        }

        if (item.id === 'new') {
            url = new URL(`/items`, apiEndpoint);
            delete saveItem.id;
        }

        const response = await apiFetch(url.toString(), {
            method: item.id === 'new' ? 'POST' : 'PATCH',
            body: JSON.stringify(saveItem),
        });

        const data = await response.json();

        if (!response.ok) {
            return enqueueSnackbar('Error saving item.', {variant: 'error'});
        }

        const newItems = items.map(newItem => {
            if (newItem.id === item.id) {
                return data;
            }

            return newItem;
        });

        setItems(newItems);
    }, [apiFetch, enqueueSnackbar, items, setItems, tempDocument, tempImage, uploadTempDocument, uploadTempImage]);

    const handleClose = () => {
        if (editMode && item.id === 'new') {
            setTempItem(item);
            setEditMode(false);
            setSelectedItem(undefined);
            setItems(items.filter(i => i.id !== 'new'));
        } else if (editMode) {
            setTempItem(item);
            setTempDocument(item.document);
            setTempImage(item.image ? item.image.large : '');
            setEditMode(false);
        } else {
            setSelectedItem(undefined);
        }
    };

    const initialValues = useMemo(() => {
        return {
            name: tempItem.name,
            category: tempItem.category,
            duration: tempItem.duration,
            availability: tempItem.availability,
            classification: tempItem.classification,
            type: tempItem.type,
            brand: tempItem.brand,
            bottleSize: tempItem.bottleSize,
            summary: tempItem.summary,
            description: tempItem.description,
            supplier_company: tempItem.supplier.company,
            supplier_contactName: tempItem.supplier.contactName,
            supplier_phoneNumber: tempItem.supplier.phoneNumber,
            supplier_emailAddress: tempItem.supplier.emailAddress,
            published: tempItem.published,
            image: tempItem.image,
            document: tempItem.document,
        }
    }, [tempItem])

    const handleSubmit = useCallback(async (formData : FormData) => {
        const values = schema.cast(formData);

        if (values !== undefined) {
            const saveItem = {
                id: tempItem.id,
                name: values.name,
                category: values.category,
                duration: values.duration,
                availability: values.availability,
                classification: values.classification,
                type: values.type,
                brand: values.brand,
                bottleSize: values.bottleSize,
                summary: values.summary,
                description: values.description,
                supplier: {
                    company: values.supplier_company,
                    contactName: values.supplier_contactName,
                    phoneNumber: values.supplier_phoneNumber,
                    emailAddress: values.supplier_emailAddress,
                },
                published: values.published,
                newImage: undefined,
            } as Item;
            patchItem(saveItem);
            setSelectedItem(undefined);
            setEditMode(false);
        }

    }, [setSelectedItem, patchItem, setEditMode, tempItem.id]);

    const handleImageChange = (image : string | undefined) => {
        setTempItem({...tempItem, image: null})
        setTempImage(image === undefined ? null : image);
        setUploadTempImage(true);
    };

    const handleDocumentChange = (document : string | undefined) => {
        setTempDocument(document === undefined ? null : document);
        setUploadTempDocument(true);
    };

    const handleDelete = () => {
        setDeleteConfirmation(true);
    };

    const mailto = () => {
        window.location.href = `mailto:${tempItem.supplier.emailAddress}`;
    };

    const deleteItem = async () => {
        const url = new URL(`/items/${tempItem.id}`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'delete',
        });

        if (!response.status) {
            return enqueueSnackbar('Error deleting item.', {variant: 'error'});
        }

        const newItems = items.filter(i => i.id !== item.id)

        setItems(newItems);
        setSelectedItem(undefined);
    };

    if (item === undefined) {
        return <CircularProgress/>;
    }

    let previewImage = tempItem.image ? tempItem.image.large : null; // image is on server
    
    if (previewImage === null && tempImage !== null && tempImage !== '') {
        previewImage = 'data:image/jpeg;base64,' + tempImage;
    }
    
    let submit : (event ?: Partial<Pick<React.SyntheticEvent<Element, Event>, "preventDefault" | "stopPropagation">> | undefined) => Promise<AnyObject | undefined> | undefined;

    let inList = false;
    
    const desciptionAsList = tempItem.description.split(/(\\*.+)/g).map((line) => {
        let returnString = '';

        if (!line.trim().length) {
            return '';
        }

        if (line.includes('*')) {
            if (!inList) {
                returnString += "<ul>";
                inList = true;
            }

            returnString += "<li>" + line.replace('*', '').trim() + '</li>';
        } else {
            if (inList) {
                inList = false;
                returnString += '</ul>';
            }

            returnString += line.trim();
        }

        return returnString;
    }).join('');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                classes: {
                    root: !editMode ? classes.backdrop : '',
                },
            }}
            maxWidth="lg"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            className={classes.modal}
        >
            <DialogContent>
                {!editMode && <React.Fragment>
                    <Grid container spacing={0} className={classes.windowColumns}>
                        <Grid item xs={12}>
                            <Typography variant="h5">{tempItem.name}</Typography>
                            <Typography variant="body1">{tempItem.category} - {tempItem.brand}</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.imageContainer}>
                            {tempItem.image?.large !== undefined && <img alt="View Item preview." src={tempItem.image?.large} width="100%"/>}
                        </Grid>
                        <Grid item xs={4} className={classes.imageContainer}>
                            <Typography className={classes.title}>Product Details</Typography>
                            <Typography className={classes.label}>Classification: </Typography>
                            <Typography className={classes.info} variant="body1">{tempItem.classification}</Typography>
                            <Typography className={classes.label}>Type: </Typography>
                            <Typography className={classes.info} variant="body1">{tempItem.type}</Typography>
                            <Typography className={classes.label}>Bottle Size: </Typography>
                            <Typography className={classes.info} variant="body1">{tempItem.bottleSize}</Typography>
                            <Typography className={classes.label}>Description</Typography>
                            <Typography className={classes.info} variant="body1" component={'span'}>{parse(desciptionAsList)}</Typography>
                            {tempItem.document?.length && <Button 
                                variant="outlined" 
                                href={tempItem.document}
                                className={classes.uploadButton}
                                style={{marginLeft:0}}
                            >
                                Download Document
                            </Button>}
                        </Grid>
                        <Grid item xs={4} className={classes.imageContainer} style={{marginRight:0}}>
                            <Typography className={classes.title}>Supplier Information </Typography>
                            {tempItem.supplier.company.length > 0 && <Typography className={classes.info} variant="body1">{tempItem.supplier.company}</Typography>}
                            {tempItem.supplier.contactName.length > 0 && <Typography className={classes.info} variant="body1">{tempItem.supplier.contactName}</Typography>}
                            {tempItem.supplier.emailAddress.length > 0 && <Typography className={classes.info} variant="body1">{tempItem.supplier.emailAddress}</Typography>}
                            {tempItem.supplier.phoneNumber.length > 0 && <Typography className={classes.info} variant="body1">{tempItem.supplier.phoneNumber}</Typography>}
                            {tempItem.supplier.emailAddress.length > 0 && <Button
                                variant="outlined"
                                disableRipple
                                onClick={mailto}
                                className={classes.uploadButton}
                                style={{marginLeft:0}}
                            >
                                Send Email
                            </Button>}
                        </Grid>
                    </Grid>
                </React.Fragment>}
                
                {editMode && <Form
                    subscription={{}}
                    onSubmit={handleSubmit}
                    validate={validate}
                    initialValues={initialValues}
                    render={({handleSubmit, form}) => {
                        submit = handleSubmit;
                        return <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit} id="productForm">
                            <Grid container spacing={0} style={{width: '100%'}}>
                                <Grid item xs={12} style={{paddingBottom: '18px'}}>
                                    <Typography variant="h5">{`${admin ? 'Edit' : 'View'} Item`}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={0} style={{width: '100%'}}>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <FileUploader
                                                admin={admin}
                                                handleImageChange={handleImageChange}
                                                previewImage={previewImage}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem} style={{paddingTop: '20px'}}>
                                            <DocumentUploader
                                                admin={admin}
                                                handleChange={handleDocumentChange}
                                                documentLink={item.document}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} style={{paddingLeft: '8px'}}>
                                    <Grid container spacing={0} style={{width: '100%', paddingLeft: '16px'}}>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                required={required.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="category"
                                                label="Category"
                                                required={required.category}
                                                options={distinctCategory.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="availability"
                                                label="Availability"
                                                required={required.availability}
                                                options={distinctAvailability.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="duration"
                                                label="Duration"
                                                required={required.duration}
                                                options={distinctDuration.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="classification"
                                                label="Classification"
                                                required={required.classification}
                                                options={distinctClassification.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="type"
                                                label="Type"
                                                required={required.type}
                                                options={distinctType.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="brand"
                                                label="Brand"
                                                required={required.brand}
                                                options={distinctBrand.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="bottleSize"
                                                label="Bottle Size"
                                                variant="outlined"
                                                required={required.bottleSize}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={0} style={{width: '100%', paddingLeft: '16px'}}>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="summary"
                                                label="Summary"
                                                variant="outlined"
                                                required={required.summary}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="description"
                                                label="Description (use * to create a bulleted list)"
                                                variant="outlined"
                                                required={required.description}
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <MuiRffAutocomplete
                                                name="supplier_company"
                                                label="Supplier Company"
                                                required={required.supplier_company}
                                                options={distinctSupplier.map((option) => option.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="supplier_contactName"
                                                label="Supplier Contact Name"
                                                variant="outlined"
                                                required={required.supplier_contactName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="supplier_phoneNumber"
                                                label="Supplier Phone"
                                                variant="outlined"
                                                required={required.supplier_phoneNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <TextField
                                                name="supplier_emailAddress"
                                                label="Supplier Email"
                                                variant="outlined"
                                                required={required.supplier_emailAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        name="published"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                }
                                                label="Publish"
                                                style={{paddingLeft: '10px'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>;
                    }}
                />}
                <AlertDialog open={deleteConfirmation} setOpen={setDeleteConfirmation} callback={deleteItem}/>
            </DialogContent>
            <DialogActions>
                <MuiThemeProvider theme={theme}>
                    <Grid container spacing={0} style={{width: '100%', paddingRight:'16px',paddingLeft:'16px', paddingBottom:'16px'}}>
                        <Grid item xs={6}>
                            {!editMode && admin && <Button
                                variant="outlined"
                                disableRipple
                                onClick={handleDelete}
                                className={classes.saveButton}
                                style={{marginLeft:0}}
                            >
                                Delete
                            </Button>}
                            {!editMode && admin && <Button
                                variant="outlined"
                                disableRipple
                                onClick={() => setEditMode(true)}
                                className={classes.saveButton}
                            >
                                Edit
                            </Button>}
                        </Grid>
                        <Grid item xs={6} style={{display:'flex', justifyContent: 'right'}}>
                            <Button
                                onClick={handleClose}
                                disableRipple
                                className={ editMode ? classes.cancelButton : classes.saveButton}
                            >
                                {editMode ? 'Cancel' : 'Close'}
                            </Button>
                            {editMode && <Button
                                onClick={event => submit(event)}
                                disableRipple
                                className={classes.saveButton}
                            >
                                Save
                            </Button>}
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </DialogActions>
        </Dialog>
    );
}

export default ItemDetailModal;