import {
    createStyles,
    StyleRules,
    Theme,
    withStyles,
    WithStyles,
    TextField,
    IconButton,
    Button,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FileSaver from 'file-saver';
import React, {useState, ReactElement} from 'react';
import {Item, FormOption} from '../pages/Home';
import DocumentIcon from '../static/icon-doc.png';
import {tokenFilter, optionsFilter} from '../utils/TableFilters';
import MultiSelectCheckbox from './MultiSelectCheckbox';
import {useAuth0} from './Providers/Auth0Provider';

const styles = (theme : Theme) : StyleRules =>
    createStyles({
        button: {
            color: theme.palette.text.secondary,
            background: theme.palette.secondary.dark,
            margin: theme.spacing(1),
        },
        fileIcon: {
            borderRadius: '4px',
            padding: '7px',
            height: '30px',
            backgroundColor: 'rgb(0, 174, 239)',
            "&:hover": {
                backgroundColor: 'rgb(0, 204, 244)'
            },
        },
        itemRow: {
            "&:hover": {
                backgroundColor: 'rgb(199, 234, 251)'
            },
        },
        buttonGroup: {
            textAlign: 'center',
        },
        link: {
            marginLeft: '74px',
            marginTop: '0px',
        },
        linkWithImage: {
            marginTop: '-50px',
        },
        checkbox: {
            color: '#00AEEF',
        },
        imagePlaceholder: {
            width: '64px',
            height: '64px',
        },
        headerCell: {
            color: '#444444',
            fontSize: '15px',
            fontWeight: 400,
            textTransform: 'uppercase',
            textAlign: 'left',
        },
        tableCell: {
            color: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            textTransform: 'none',
            textAlign: 'left',
        },
    });

type Props = WithStyles<typeof styles> & {
    items : Item[];
    selectItemForModal : (item : Item) => void;
    distinctCategory : FormOption[];
    distinctDuration : FormOption[];
    distinctAvailability : FormOption[];
    distinctClassification : FormOption[];
    distinctType : FormOption[];
};

const ItemTable = ({
    classes,
    items,
    selectItemForModal,
    distinctCategory,
    distinctDuration,
    distinctAvailability,
    distinctClassification,
    distinctType,
} : Props) : ReactElement => {
    const auth0 = useAuth0();
    const admin = auth0.roles.includes('admin');
    const [name, setName] = useState('');
    const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
    const [durationOptions, setDurationOptions] = useState<string[]>([]);
    const [availabilityOptions, setAvailabilityOptions] = useState<string[]>([]);
    const [classificationOptions, setClassificationOptions] = useState<string[]>([]);
    const [typeOptions, setTypeOptions] = useState<string[]>([]);
    const [brand, setBrand] = useState('');
    const [bottleSize, setBottleSize] = useState('');
    const [summary, setSummary] = useState('');
    const [supplier, setSupplier] = useState('');

    let displayedItems = items.filter(item => admin || item.published);
    displayedItems = tokenFilter(displayedItems, 'name', name);
    displayedItems = optionsFilter(displayedItems, 'category', categoryOptions);
    displayedItems = optionsFilter(displayedItems, 'duration', durationOptions);
    displayedItems = optionsFilter(displayedItems, 'availability', availabilityOptions);
    displayedItems = optionsFilter(displayedItems, 'classification', classificationOptions);
    displayedItems = optionsFilter(displayedItems, 'type', typeOptions);
    displayedItems = tokenFilter(displayedItems, 'brand', brand);
    displayedItems = tokenFilter(displayedItems, 'bottleSize', bottleSize);
    displayedItems = tokenFilter(displayedItems, 'summary', summary);
    displayedItems = tokenFilter(displayedItems, 'supplier', supplier, 'company');

    const showClearButton = (name !== '' || bottleSize !== '' || summary !== '') || (categoryOptions.length || availabilityOptions.length || durationOptions.length || classificationOptions.length || typeOptions.length || brand !== '' || supplier !== '') ? true : false;

    const clearFilters = () => {
        setName('');
        setCategoryOptions([]);
        setAvailabilityOptions([]);
        setDurationOptions([]);
        setClassificationOptions([]);
        setTypeOptions([]);
        setBrand('');
        setBottleSize('');
        setSummary('');
        setSupplier('');
    }

    const handleDownload = (event : React.MouseEvent<HTMLButtonElement>, item : Item) => {
        event.preventDefault();
        event.stopPropagation();

        if (item.document) {
            FileSaver.saveAs(item.document, item.name + '.jpg' ?? 'document.jpg');
        }
    }

    return (
        <Table>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell className={classes.headerCell}>
                        {showClearButton && <Button variant="contained" className={classes.addNewItem} onClick={clearFilters}>Clear All</Button>}
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <TextField
                            id="name"
                            label="Name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <MultiSelectCheckbox
                            label="Category"
                            selectedOptions={categoryOptions}
                            setSelectedOptions={setCategoryOptions}
                            options={distinctCategory}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <MultiSelectCheckbox
                            label="Availability"
                            selectedOptions={availabilityOptions}
                            setSelectedOptions={setAvailabilityOptions}
                            options={distinctAvailability}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <MultiSelectCheckbox
                            label="Duration"
                            selectedOptions={durationOptions}
                            setSelectedOptions={setDurationOptions}
                            options={distinctDuration}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <MultiSelectCheckbox
                            label="Classification"
                            selectedOptions={classificationOptions}
                            setSelectedOptions={setClassificationOptions}
                            options={distinctClassification}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <MultiSelectCheckbox
                            label="Type"
                            selectedOptions={typeOptions}
                            setSelectedOptions={setTypeOptions}
                            options={distinctType}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <TextField
                            id="brand"
                            label="Brand"
                            value={brand}
                            onChange={e => setBrand(e.target.value)}
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <TextField
                            id="bottle-size"
                            label="Bottle Size"
                            value={bottleSize}
                            onChange={e => setBottleSize(e.target.value)}
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <TextField
                            id="summary"
                            label="Summary"
                            value={summary}
                            onChange={e => setSummary(e.target.value)}
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        <TextField
                            id="supplier"
                            label="Supplier"
                            value={supplier}
                            onChange={e => setSupplier(e.target.value)}
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {displayedItems.length === 0 && (
                    <React.Fragment key="ROW-0">
                        <TableRow>
                            <TableCell colSpan={12}>NO items FOUND</TableCell>
                        </TableRow>
                    </React.Fragment>
                )}
                {displayedItems.map(item => {
                    return (
                        <React.Fragment key={'ROW-' + item.id}>
                            <TableRow
                                className={classes.itemRow}
                                onClick={() => selectItemForModal(item)}
                            >
                                <TableCell className={classes.tableCell} style={{whiteSpace: 'nowrap'}}>
                                    {item.image && <img alt='Item thumbnail' src={item.image.thumb} width="64" height="64"/>}
                                    {item.document && <IconButton
                                        aria-label="download document"
                                        className={item.image ? classes.linkWithImage : classes.link}
                                        onClick={e => handleDownload(e, item)}
                                    >
                                        <img src={DocumentIcon} alt="Logo" className={classes.fileIcon}/>
                                    </IconButton>}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.category}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.availability}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.duration}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.classification}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.type}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.brand}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.bottleSize}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.summary}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {item.supplier.company}
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default withStyles(styles)(ItemTable);
