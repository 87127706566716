import {Button} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSnackbar} from 'notistack';
import React, {useState, useEffect, ReactElement} from 'react';
import {useDropzone} from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles(({
    uploadButton: {
        backgroundColor: 'rgb(0, 89, 138)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(0, 118, 171)'
        },
        border: 'none',
        marginTop: '20px',
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
    },
    dropZone: {
        height: '300px',
        lineHeight: '300px',
        backgroundColor: '#fff',
        margin: 'auto',
        border: '1px dotted #000',
        textAlign: 'center',
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%',
    },
}));

type Props = {
    admin : boolean;
    handleImageChange : (image : string | undefined) => void;
    noFilesMessage ?: string;
    previewImage ?: string | null;
    label ?: string;
};

const ImageUploader = ({admin, handleImageChange, noFilesMessage, previewImage, label} : Props) : ReactElement => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();    
    const [files, setFiles] = useState<File[]>([]);
    const [haveImage, setHaveImage] = useState(previewImage === null ? false : true);

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 1) {
                return enqueueSnackbar(`One ${label} limit.`, {variant: 'error'});
            }

            if (acceptedFiles.length === 0) {
                return enqueueSnackbar(`Could not parse an acceptable file.`, {variant: 'error'});
            }

            setFiles(acceptedFiles.map(file => ({
                ...file,
                preview: URL.createObjectURL(file),
            })));

            Resizer.imageFileResizer(
                acceptedFiles[0],
                1024,
                1024,
                'JPEG',
                100,
                0,
                uri => {
                    handleImageChange(uri.toString().substring('data:image/jpeg;base64,'.length));
                    setHaveImage(true);
                },
                'string'
            );
        },
    });

    type File = {
        preview : string;
        name : string;
    };

    useEffect(
        () => () => {
            if (files.length) {
                // Make sure to revoke the data uris to avoid memory leaks
                files.forEach(file => URL.revokeObjectURL(file.preview));
            }
        },
        [files]
    );

    const handleRemoveImage = () => {
        handleImageChange(undefined);
        setHaveImage(false);
        setFiles([]);
    };

    return (
        <section className="container">
            {previewImage && (
                <React.Fragment>
                    {previewImage !== null && <img alt="Item Preview" src={previewImage} width="100%"/>}
                    {admin && <Button
                        onClick={handleRemoveImage}
                        variant="outlined"
                        disableRipple
                        className={classes.uploadButton}
                        style={{marginLeft:0}}
                    >
                        {`Delete`}
                    </Button>}
                </React.Fragment>
            )}
            {admin && !previewImage && (
                <React.Fragment>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()}/>
                        <p className={classes.dropZone}>{noFilesMessage}</p>
                    </div>
                    {admin && <Button
                        onClick={open}
                        variant="outlined"
                        disableRipple
                        className={classes.uploadButton}
                        style={{marginLeft:0}}
                    >
                        {(haveImage ? 'Replace ' : 'Upload ') + label}
                    </Button>}
                    {admin && haveImage && <Button
                        onClick={handleRemoveImage}
                        variant="outlined"
                        disableRipple
                        className={classes.uploadButton}
                    >
                        {`Remove ${label}`}
                    </Button>}
                </React.Fragment>
            )}
        </section>
    );
}

ImageUploader.defaultProps = {
    noFilesMessage: 'Drag drop, or click to select an image.',
    label: 'Image',
};

export default ImageUploader;