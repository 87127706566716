import createAuth0Client, {Auth0Client, LogoutOptions} from "@auth0/auth0-spa-js";
import {Auth0ClientOptions} from '@auth0/auth0-spa-js/dist/typings/global';
import {GetTokenSilentlyOptions, RedirectLoginOptions} from '@auth0/auth0-spa-js/src/global';
import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router';

export type Auth0User = {
    name : string;
    email : string;
};

export type Auth0State = {
    isAuthenticated : boolean;
    user : Auth0User | null;
    roles : string[];
    loading : boolean;
    loginWithRedirect : (options ? : RedirectLoginOptions) => Promise<void>
    logout : (options ? : LogoutOptions) => void;
    getTokenSilently : (options ? : GetTokenSilentlyOptions) => Promise<string>,
};

const auth0Context = React.createContext<Auth0State | null>(null);

type Props = {
    auth0Options : Auth0ClientOptions;
    children ? : ReactElement;
};

const uninitializedFallback = () => Promise.reject();

const Auth0Provider = ({auth0Options, children} : Props) : ReactElement => {
    const history = useHistory();
    const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<Auth0User | null>(null);
    const [roles, setRoles] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const client = await createAuth0Client(auth0Options);
            setAuth0Client(client);

            const {search} = window.location;

            if (search.includes('code=') && search.includes('state=')) {
                const {appState} = await client.handleRedirectCallback();
                history.replace(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
            }

            const isAuthenticated = await client.isAuthenticated();
            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await client.getUser();
                const roles = (await client.getIdTokenClaims())['https://com.phcp.catalog/roles'];
                setUser(user);
                setRoles(roles);
            }

            setLoading(false);
        })();
    }, [auth0Options, setAuth0Client, history]);

    const value : Auth0State = {
        isAuthenticated,
        user,
        roles,
        loading,
        loginWithRedirect: uninitializedFallback,
        getTokenSilently: uninitializedFallback,
        logout: uninitializedFallback,
    };

    if (auth0Client) {
        value.loginWithRedirect = (options ? : RedirectLoginOptions) => auth0Client.loginWithRedirect(options);
        value.getTokenSilently = (options ? : GetTokenSilentlyOptions) => auth0Client.getTokenSilently(options);
        value.logout = (options ? : LogoutOptions) => auth0Client.logout(options);
    }

    return (
        <auth0Context.Provider value={value}>
            {children}
        </auth0Context.Provider>
    );
};

if (process.env.REACT_APP_API_ENDPOINT === undefined) {
    throw new Error('Your environment is improperly configured!');
}

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const useAuth0 = () : Auth0State => {
    const auth0 = useContext(auth0Context);

    if (!auth0) {
        throw new Error('Context was used before initial load');
    }

    return auth0;
};

export const useApiFetch = () : (url : string, init ? : RequestInit) => Promise<Response> => {
    const auth0 = useContext(auth0Context);

    if (!auth0) {
        throw new Error('Context was used before initial load');
    }

    return useCallback(async (url : string, init ? : RequestInit) : Promise<Response> => {
        let accessToken : string;

        try {
            accessToken = await auth0.getTokenSilently();
        } catch (e) {
            await auth0.loginWithRedirect();
            throw e;
        }

        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${accessToken}`);
        init.headers.append('Content-Type', 'application/json');

        return fetch(url, init);
    }, [auth0]);
};

export default Auth0Provider;
