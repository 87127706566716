import React, {useEffect, ReactElement} from 'react';
import {BrowserRouter} from 'react-router-dom';
import FullPageLoadingIndicator from './components/FullPageLoadingIndicator';
import Auth0Provider, {useAuth0} from './components/Providers/Auth0Provider';
import Main from './Main';
import {baseUrl} from './utils/Helpers';

const Guard = () => {
    const auth0 = useAuth0();

    useEffect(() => {
        if (!auth0.loading && (!auth0.isAuthenticated || !auth0.user)) {
            auth0.loginWithRedirect();
        }
    }, [auth0]);

    if (auth0.loading || !auth0.user) {
        return <FullPageLoadingIndicator/>;
    }

    return <Main/>;
};

const App = () : ReactElement => {
    if (process.env.REACT_APP_AUTH0_DOMAIN === undefined || process.env.REACT_APP_AUTH0_CLIENT_ID === undefined || process.env.REACT_APP_AUTH0_AUDIENCE === undefined) {
        throw new Error('Your environment is improperly configured!');
    }

    return <BrowserRouter>
        <Auth0Provider auth0Options={{
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: baseUrl,
            cacheLocation: 'localstorage',
        }}>
            <Guard/>
        </Auth0Provider>
    </BrowserRouter>

};

export default App;
