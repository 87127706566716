import {Button, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSnackbar} from 'notistack';
import React, {useState, useEffect, ReactElement} from 'react';
import {useDropzone} from 'react-dropzone';

const useStyles = makeStyles(({
    uploadButton: {
        backgroundColor: 'rgb(0, 89, 138)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(0, 118, 171)'
        },
        border: 'none',
        marginTop: '20px',
        marginRight: '10px',
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
    },
    dropZone: {
        backgroundColor: '#fff',
        margin: 'auto',
        border: '1px dotted #000',
        textAlign: 'center',
        paddingTop: '8px',
        height: '40px',
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%',
    },
}));

type Props = {
    admin : boolean;
    handleChange : (document : string | undefined) => void;
    noFilesMessage ?: string;
    documentLink ?: string | null;
    label ?: string;
};

const DocumentUploader = ({admin, handleChange, noFilesMessage, documentLink, label} : Props) : ReactElement => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [files, setFiles] = useState<File[]>([]);
    const [haveDocument, setHaveDocument] = useState(documentLink === null ? false : true);
    const [localDocment, setLocalDocument] = useState(documentLink);

    const {getRootProps, getInputProps, open} = useDropzone({
        accept: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 1) {
                return enqueueSnackbar(`One ${label} limit.`, {variant: 'error'});
            }

            if (acceptedFiles.length === 0) {
                return enqueueSnackbar(`Could not parse an acceptable file.`, {variant: 'error'});
            }

            setFiles(acceptedFiles.map(file => ({
                ...file,
                preview: URL.createObjectURL(file),
            })));

            const reader = new FileReader();

            reader.onloadend = () => {
                handleChange(reader.result?.toString().split(',')[1]);
                setHaveDocument(true);
            };
            
            reader.readAsDataURL(acceptedFiles[0]);
        },
    });

    type File = {
        preview : string;
        name : string;
        path ? : string;
    };

    useEffect(
        () => () => {
            if (files.length) {
                // Make sure to revoke the data uris to avoid memory leaks
                files.forEach(file => URL.revokeObjectURL(file.preview));
            }
        },
        [files]
    );

    const handleRemoveDocument = () => {
        setLocalDocument(null);
        handleChange(undefined);
        setHaveDocument(false);
        setFiles([]);
    };

    return (
        <section className="container">
            {documentLink && localDocment && (
                <Button 
                    variant="outlined" 
                    href={documentLink}
                    className={classes.uploadButton}
                    style={{marginLeft:0}}
                >
                    Download File
                </Button>
            )}
            {admin && localDocment && <Button
                onClick={handleRemoveDocument}
                variant="outlined"
                disableRipple
                className={classes.uploadButton}
                style={{marginLeft:0}}
            >
                {`Remove ${label}`}
            </Button>}
            {admin && !localDocment && (
                <React.Fragment>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()}/>
                        <p className={classes.dropZone}>{noFilesMessage}</p>
                    </div>
                    <aside className={classes.thumbsContainer}>
                        {files.map((file, index) => {
                            return <Typography key={`document-preview-${index}`} variant="body1">{file.path} waiting to upload.</Typography>
                        })}
                    </aside>
                    <Button
                        onClick={open}
                        variant="outlined"
                        disableRipple
                        className={classes.uploadButton}
                        style={{marginLeft:0}}
                    >
                        {(haveDocument ? 'Replace ' : 'Upload ') + label}
                    </Button>
                    {haveDocument && <Button
                        onClick={handleRemoveDocument}
                        variant="outlined"
                        disableRipple
                        className={classes.uploadButton}
                    >
                        {`Remove ${label}`}
                    </Button>}
                </React.Fragment>
            )}
        </section>
    );
}

DocumentUploader.defaultProps = {
    noFilesMessage: 'Drag drop, or click to select a file.',
    preview: true,
    label: 'File',
};

export default DocumentUploader;