import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import React, {ReactElement} from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: theme.spacing(2, 0),
    },
}));

type OwnProps = {
    className ? : string;
};

type Props = OwnProps;

const InlineLoadingIndicator = ({className} : Props) : ReactElement => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, className)}><CircularProgress/></div>
    );
};

export default InlineLoadingIndicator;
