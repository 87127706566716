import {Typography, Button, Grid} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useEffect, useState, ReactElement} from 'react';
import InlineLoadingIndicator from '../components/InlineLoadingIndicator';
import ItemDetailModal from '../components/ItemDetailModal';
import ItemTable from '../components/ItemTable';
import {
    apiEndpoint,
    useApiFetch,
    useAuth0,
} from '../components/Providers/Auth0Provider';

const useStyles = makeStyles(({
    addNewItem: {
        backgroundColor: 'rgb(0, 174, 239)',
        color: 'white',
        "&:hover": {
            backgroundColor: 'rgb(0, 204, 244)'
        },
    },
}));

export type Item = {
    id : string;
    name : string;
    category : string;
    availability : string;
    duration : string;
    classification : string;
    type : string;
    brand : string;
    bottleSize : string;
    summary : string;
    description : string;
    supplier : {
        company : string;
        contactName : string;
        phoneNumber : string;
        emailAddress : string;
    };
    published : boolean;
    image : {
        large : string;
        thumb : string;
    } | null;
    document : string | null;
    newImage : string | null | undefined;
};

export type FormOption = {
    label : string, 
    value : string
};

const Home = () : ReactElement => {
    const classes = useStyles();
    const auth0 = useAuth0();
    const apiFetch = useApiFetch();
    const [items, setItems] = useState<Item[] | null>(null);
    const [selectedItem, setSelectedItem] = useState<Item | undefined>();
    const [editMode, setEditMode] = useState(false);

    const distinctCategory = [...new Map(items?.map(item => [item.category, item])).values()].map(item => {
        return {label: item.category, value: item.category};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctAvailability = [...new Map(items?.map(item => [item.availability, item])).values()].map(item => {
        return {label: item.availability, value: item.availability};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctDuration = [...new Map(items?.map(item => [item.duration, item])).values()].map(item => {
        return {label: item.duration, value: item.duration};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctClassification = [...new Map(items?.map(item => [item.classification, item])).values()].map(item => {
        return {label: item.classification, value: item.classification};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctType = [...new Map(items?.map(item => [item.type, item])).values()].map(item => {
        return {label: item.type, value: item.type};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctBrand = [...new Map(items?.map(item => [item.brand, item])).values()].map(item => {
        return {label: item.brand, value: item.brand};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    const distinctSupplier = [...new Map(items?.map(item => [item.supplier.company, item])).values()].map(item => {
        return {label: item.supplier.company, value: item.supplier.company};
    }).sort((a, b) => {
        return (a.label).localeCompare(b.label);
    });

    useEffect(() => {
        (async () => {
            const response = await apiFetch(
                new URL('/items', apiEndpoint).toString()
            );
            const items = await response.json();
            setItems(items);
        })();
    }, [apiFetch]);

    const createItem = async () => {
        const data = {
            id: 'new',
            name: '',
            category: '',
            availability: '',
            duration: '',
            classification: '',
            type: '',
            brand: '',
            bottleSize: '',
            summary: '',
            description: '',
            supplier: {
                company: '',
                contactName: '',
                phoneNumber: '',
                emailAddress: '',
            },
            published: false,
            image: null,
            document: null,
            newImage: undefined,
        };

        const newItems = items !== null ? [...items] : [];
        newItems.push(data);

        setItems(newItems);
        setSelectedItem(data);
        setEditMode(true);
    };

    return (
        <>
            <Grid container alignItems="flex-end" justify="space-between" direction="row" style={{width: '100%'}}>
                <Grid item xs={10}>
                    <Typography variant="h6" style={{display: 'inline-block'}}>
                        NAOP Catalog: To Go • Take Out • Seasonal
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                    {auth0.roles.includes('admin') && <Button variant="contained" className={classes.addNewItem} onClick={() => { createItem(); }}>Add New Item</Button>}
                </Grid>
            </Grid>

            {items === null ? (
                <InlineLoadingIndicator/>
            ) : (
                <div style={{overflowX: 'scroll', width: '100%'}}>
                    <ItemTable 
                        items={items} 
                        selectItemForModal={setSelectedItem}
                        distinctCategory={distinctCategory}
                        distinctAvailability={distinctAvailability}
                        distinctDuration={distinctDuration}
                        distinctClassification={distinctClassification}
                        distinctType={distinctType}
                    />
                </div>
            )}

            {items !== null && selectedItem !== undefined && (
                <ItemDetailModal
                    open={selectedItem !== undefined}
                    setSelectedItem={setSelectedItem}
                    item={selectedItem}
                    items={items}
                    setItems={setItems}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    distinctCategory={distinctCategory}
                    distinctAvailability={distinctAvailability}
                    distinctDuration={distinctDuration}
                    distinctClassification={distinctClassification}
                    distinctType={distinctType}
                    distinctBrand={distinctBrand}
                    distinctSupplier={distinctSupplier}
                />
            )}
        </>
    );
};

export default Home;
