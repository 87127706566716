import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {createStyles, makeStyles, useTheme, Theme} from '@material-ui/core/styles';
import React, {ReactElement} from 'react';

const useStyles = makeStyles((theme : Theme) =>
    createStyles({
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 150,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (name : string, selectedOptions : string[], theme : Theme) => {
    return {
        fontWeight:
            selectedOptions.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
export type TypeaheadOption = {
    label : string;
    value : string;
};

type Props = {
    label : string;
    options : TypeaheadOption[];
    setSelectedOptions : (selectedOptions : string[]) => void;
    selectedOptions : string[];
};

const MultiSelectCheckbox = ({label, setSelectedOptions, options, selectedOptions} : Props) : ReactElement => {
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = (event : React.ChangeEvent<{ value : unknown }>) => {
        setSelectedOptions(event.target.value as string[]);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">{label}</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip"/>}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {(selected as string[]).map((value) => (
                                <Chip key={value} label={value} className={classes.chip}/>
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value} style={getStyles(option.value, selectedOptions, theme)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultiSelectCheckbox;