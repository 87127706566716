import {Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';

const NotFound = () : ReactElement => (
    <>
        <Typography variant="h4">File not found</Typography>
        <Typography variant="body1">
            The requested file could not be found.
        </Typography>
    </>
);

export default NotFound;
