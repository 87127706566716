import React, {ReactElement} from 'react';
import {Route, Switch} from 'react-router';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

const Routes = () : ReactElement => (
    <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="*" component={NotFound}/>
    </Switch>
);

export default Routes;
